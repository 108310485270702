// AboutMe.js

export const aboutMeDescription = "Hi Im Randall ";
export const aboutMeDescription1 = "Frontend Developer";
export const aboutMeDescription2 = "I like to create and collaborate";
export const aboutMeDescription3 = "Thanks for stopping by!";


export const ceeLoDescription = "Hello, I'm thrilled to present my very first bootcamp project, which involved coding a Cee-Lo game using vanilla JavaScript, HTML, and CSS3. Cee-Lo happens to be one of my favorite dice games, and working on this project was an immensely enjoyable experience. It marked the beginning of my journey into coding simple logic and exploring user interface design concepts."
export const wienersDescription = "I developed an app using the MERN stack that allowed users to upload pictures of their dogs on a website dedicated to showcasing the mountains their dogs have climbed. The backend, built with Express and MongoDB using Mongoose schemas/models, supported full CRUD operations for managing dog and mountain data. The frontend, developed with React, incorporated flexbox and/or grid for visually appealing layouts. React Router enabled seamless navigation between different pages. The app was deployed on Heroku, and a comprehensive README.md provided installation instructions, project overview, and relevant links."
export const creatureDescription = " Our team of four developers successfully completed the development of a social app on the PERN (PostgreSQL, Express, React, Node.js) stack, integrating OAuth for authentication. Despite facing tight deadlines, we delivered a feature-rich application that allowed users to create an account, build personalized profiles, post events, and RSVP to events. This app implemented full CRUD functionality, empowering users to perform Create, Read, Update, and Delete operations on their account, profile, and event data. The backend of the app was built using Express and Node.js, utilizing PostgreSQL as the database to store user and event information. OAuth was integrated to ensure secure and seamless authentication. The frontend was developed with React, providing a dynamic and intuitive user interface that allowed users to interact with the app's features effortlessly. Our team collaborated closely, leveraging effective project management and communication strategies to meet the tight deadlines. We followed best coding practices, ensuring proper code structure, indentation, and meaningful comments. Git was used for version control, enabling efficient collaboration and tracking of individual contributions. Despite the project's complexity, we successfully implemented the desired features, allowing users to create accounts, build profiles, post events, and RSVP to events. This social app served as a comprehensive platform for users to connect, share experiences, and engage in various events within the community."
export const calPalDescription = "I developed a personal PERN (PostgreSQL, Express, React, Node.js) app focused on adding food items and their corresponding calories to a database, enabling users to calculate the total calories in each meal. To manage the state of the application, I utilized Redux, a popular library for state management in JavaScript.This app was created out of a personal need to simplify the process of tracking calories for meals without the hassle of looking up basic ingredient information every night. By leveraging Redux for state management, I ensured a robust and efficient flow of data throughout the application.The backend of the app was built using Express and Node.js, with PostgreSQL serving as the database for storing food items and their calorie values. The frontend was developed using React, providing a user-friendly interface for adding food items and organizing them into meals. The integration of Redux allowed for seamless synchronization between the frontend and backend, ensuring a smooth user experience.The app allowed users to easily calculate the total calorie count of each meal by summing up the calories of the included food items. By utilizing Redux, I effectively managed the state of the application, enabling real-time updates and ensuring accurate calculations.By employing this app, both my partner and I experienced significant improvements in our meal planning and calorie tracking routine. We no longer had to manually search for calorie information each night, as the app provided a centralized database with the necessary food and calorie data readily available. This simplified the process, making it easier to maintain a balanced and healthy diet.Overall, the development of this PERN app, with Redux for state management, was a successful endeavor. It resulted in a personal tool that effectively addressed our calorie tracking needs and provided a more streamlined approach to meal planning."

export default aboutMeDescription